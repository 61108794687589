import React, { useState } from 'react';
import './ContactUs.css';
import calendarIcon from '../../assets/calendar.svg';
import discordIcon from '../../assets/discord.svg';

function ContactUs() {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert('Your message has been sent!');
    setFormData({ name: '', email: '', message: '' });
  };

  return (
    <div className='main-container'>
      <h2>Contact Us</h2>
      <p>We'd love to hear from you! Whether you have a question or want to discuss a project, feel free to reach out.</p>

      <form className='contact-form' onSubmit={handleSubmit}>
        <label htmlFor='name'>Name:</label>
        <input 
          type='text' 
          id='name' 
          name='name' 
          value={formData.name} 
          onChange={handleChange} 
          required 
        />

        <label htmlFor='email'>Email:</label>
        <input 
          type='email' 
          id='email' 
          name='email' 
          value={formData.email} 
          onChange={handleChange} 
          required 
        />

        <label htmlFor='message'>Message:</label>
        <textarea 
          id='message' 
          name='message' 
          rows='5' 
          value={formData.message} 
          onChange={handleChange} 
          required 
        />

        <button type='submit' className='submit-button'>Send Message</button>
      </form>

      <div className='contact-buttons'>
        <a 
          href='https://discord.gg/z4arBJUpEE' 
          className='contact-button'
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={discordIcon} alt="Discord" className="icon" /> Join Discord
        </a>
        <a 
          href='https://calendly.com/example' 
          className='contact-button'
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={calendarIcon} alt="Calendar" className="icon" /> Book a Session
        </a>
      </div>
    </div>
  );
}

export default ContactUs;
