import React from 'react';
import './App.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import Home from '../src/pages/Home/index';
import ContactUs from '../src/pages/ContactUs/ContactUs';
import Services from '../src/pages/Services/Services';

import BootstrapNav from './components/BootstrapNav/BootstrapNav';

function App() {

  return (
    <div className="wrapper">
  
    <Router >
      <BootstrapNav />
      <Container>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services/>} />
        <Route path="/contactUs" element={<ContactUs/>} />
      </Routes>
      </Container>
    </Router>
    </div>
  );
}

export default App;